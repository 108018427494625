import React, { useReducer } from 'react';
import { createSlug, calculateVendorRating, formatDate } from '../helpers/utils';
import { readContract } from '@wagmi/core';
import { getContract } from '@wagmi/core';
import Web3 from 'web3';
import Integration from '../integration/TokenAbi.json';

import ShopContext from './shop-context';

const defaultShopState = {
    shopContract: null,
    membershipContract: null,
    shopContractAbi: null,
    membershipAbi: null,
    shopCreationCost: 0,
    shopVerifyCost: 0,
    goldCheckCost: 0,
    paymentTokenContract: null,
    paymentToken: null,
    stToken: null,
    tokenBalance: 0,
    allShops: [],
    allProducts: [],
    digitalFiles: [],
    productReviews: [],
    pendingProducts: [],
    totalProducts: [],
    userProducts: [],
    userShops: [],
    fetchingAllShops: true,
    fetchingAllProducts: true,
    promotionPrice: 0,
    tokenInUSD: 0,
    usdToken: 0,
};

const shopReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            shopContract: action.shopContract,
        };
    }
    if (action.type === 'MEMBERSHIP_CONTRACT') {
        return {
            ...state,
            membershipContract: action.membershipContract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            shopContractAbi: action.shopContractAbi,
        };
    }
    if (action.type === 'GET_MEMBERSHIP_ABI') {
        return {
            ...state,
            membershipAbi: action.membershipAbi,
        };
    }
    if (action.type === 'GET_BALANCE') {
        return {
            ...state,
            tokenBalance: Number(action.tokenBalance).toLocaleString('fullwide', { useGrouping: false }),
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            shopContractAbi: action.shopContractAbi,
        };
    }
    if (action.type === 'GETPAYMENTTOKEN') {
        return {
            ...state,
            paymentToken: action.paymentToken,
        };
    }
    if (action.type === 'GET_ST_TOKEN') {
        return {
            ...state,
            stToken: action.stToken,
        };
    }
    if (action.type === 'LOAD_SHOP_CREATION_COST') {
        return {
            ...state,
            shopCreationCost: Number(Web3.utils.fromWei(action.shopCreationCost.toString(), 'ether')).toLocaleString(
                'fullwide',
                { useGrouping: false }
            ),
        };
    }
    if (action.type === 'LOAD_SHOP_VERIFY_COST') {
        return {
            ...state,
            shopVerifyCost: Number(Web3.utils.fromWei(action.shopVerifyCost.toString(), 'ether')).toLocaleString(
                'fullwide',
                { useGrouping: false }
            ),
        };
    }
    if (action.type === 'LOAD_GOLD_CHECK_COST') {
        return {
            ...state,
            goldCheckCost: Number(Web3.utils.fromWei(action.goldCheckCost.toString(), 'ether')).toLocaleString(
                'fullwide',
                { useGrouping: false }
            ),
        };
    }
    if (action.type === 'GET_TOKEN_IN_USD') {
        return {
            ...state,
            tokenInUSD: action.payload.tokenInUSD,
            usdToken: action.payload.usdToken,
        };
    }
    if (action.type === 'GETPAYMENTTOKENCONTRACT') {
        return {
            ...state,
            paymentTokenContract: action.paymentTokenContract,
        };
    }
    if (action.type === 'LOAD_PRODUCT_REVIEWS') {
        return {
            ...state,
            productReviews: action.reviews?.map((rate) => {
                return {
                    raterName: rate[4][1][0],
                    raterProfile: rate[4][1][3],
                    raterAddress: rate[0],
                    rateReason: rate[2],
                    rateMsg: rate[3],
                    rate: Number(rate[1]) / 10,
                };
            }),
        };
    }
    if (action.type === 'LOAD_ALL_SHOPS') {
        return {
            ...state,
            fetchingAllShops: false,
            allShops: action.payload.allShops?.map((shop) => {
                return {
                    shopId: Number(shop[0]),
                    shopOwner: shop[1][0],
                    shopOwnerName: shop[1][1],
                    shopOwnerProfile: shop[1][2],
                    shopTitle: shop[2][0],
                    shopSlug: createSlug(shop[2][0]),
                    shopDescription: shop[2][1],
                    shopCategory: shop[2][2],
                    shopEmail: shop[2][3],
                    shopCover: shop[2][4],
                    shopLogo: shop[2][5],
                    shopIsBlocked: shop[3],
                    shopProducts:
                        Number(shop[4]) +
                        state?.digitalFiles
                            ?.filter((product) => product?.productShopId === Number(shop[0]))
                            ?.map((product) => {
                                return {
                                    productRating: product?.productRating,
                                };
                            })?.length,
                    shopRating: calculateVendorRating([
                        ...action.payload?.allProducts
                            ?.filter((product) => Number(product[2][0]) === Number(shop[0]))
                            ?.map((product) => {
                                return {
                                    productRating: Number(product[7]) / 10,
                                };
                            }),
                        ...state?.digitalFiles
                            ?.filter((product) => product?.productShopId === Number(shop[0]))
                            ?.map((product) => {
                                return {
                                    productRating: product?.productRating,
                                };
                            }),
                    ]),
                    df: state?.digitalFiles,
                    shopDocs:
                        shop[5][0] !== ''
                            ? {
                                  companyName: shop[5][0],
                                  companyAddress: shop[5][1],
                                  phoneNumber: shop[5][2],
                                  email: shop[5][3],
                                  certificate: shop[5][4],
                                  twitterProfile: shop[5][5],
                              }
                            : [],

                    vip: new Date(Number(shop[7]) * 1000) > new Date().getTime(),
                    isVerified: shop[6],
                };
            }),
        };
    }
    if (action.type === 'LOAD_USER_SHOPS') {
        return {
            ...state,
            userShops: action.userShops?.map((shop) => {
                return {
                    shopId: Number(shop[0]),
                    shopOwner: shop[1][0],
                    shopOwnerName: shop[1][1],
                    shopOwnerProfile: shop[1][2],
                    shopTitle: shop[2][0],
                    shopSlug: createSlug(shop[2][0]),
                    shopDescription: shop[2][1],
                    shopCategory: shop[2][2],
                    shopEmail: shop[2][3],
                    shopCover: shop[2][4],
                    shopLogo: shop[2][5],
                    shopIsBlocked: shop[3],
                    shopProducts: Number(shop[4]),
                    shopRating: 0,
                    shopDocs:
                        shop[5][0] !== ''
                            ? {
                                  companyName: shop[5][0],
                                  companyAddress: shop[5][1],
                                  phoneNumber: shop[5][2],
                                  email: shop[5][3],
                                  certificate: shop[5][4],
                                  twitterProfile: shop[5][5],
                              }
                            : [],

                    vip: new Date(Number(shop[7]) * 1000) > new Date().getTime(),
                    isVerified: shop[6],
                };
            }),
        };
    }

    if (action.type === 'LOAD_ALL_PRODUCTS') {
        const products = action.allProducts?.map((product) => {
            return {
                productOwner: product[0],
                productId: Number(product[1]),
                productShopId: Number(product[2][0]),
                productShopName: product[2][1],
                productShopSlug: createSlug(product[2][1]),
                productShopLogo: product[2][2],
                productName: product[3][0],
                productSlug: createSlug(product[3][0]),
                productDescription: product[3][1],
                productCategory: product[3][2],
                productGallery: product[3][3],
                productInfo: product[3][4],
                productPrice: Number(
                    Web3.utils.fromWei(
                        Number(product[3][5]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                        'ether'
                    )
                ),
                productTokenPrice:
                    Number(
                        Web3.utils.fromWei(
                            Number(product[3][5]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                            'ether'
                        )
                    ) * usdToken,
                productWeight: Web3.utils.fromWei(Number(product[3][6]).toString(), 'ether'),
                productDimensions: product[3][7],
                productAvailability: Number(product[3][8]),
                returnPolicy: product[3][9],
                productCondition: product[3][10],
                productRating: Number(product[7]) / 10,
                isApproved: true,
                isBlocked: product[6],
                promoted: Number(product[9]) * 1000 > new Date().getTime(),
                prmotionEndTime: formatDate(Number(product[9]) * 1000),

                shippingPrice: product[5].map((price, index) => {
                    return {
                        label:
                            index === 0
                                ? 'europe'
                                : index === 1
                                ? 'asia'
                                : index === 2
                                ? 'africa'
                                : index === 3
                                ? 'north_america'
                                : index === 4
                                ? 'south_america'
                                : index === 5
                                ? 'antarctica'
                                : index === 6 && 'australia',
                        value: Number(
                            Web3.utils.fromWei(
                                Number(price).toLocaleString('fullwide', { useGrouping: false }),
                                'ether'
                            )
                        ),
                        tokenValue:
                            Number(
                                Web3.utils.fromWei(
                                    Number(price).toLocaleString('fullwide', { useGrouping: false }),
                                    'ether'
                                )
                            ) * usdToken,
                    };
                }),
            };
        });
        return {
            ...state,
            allProducts: products.filter((product) => product?.isApproved),
            pendingProducts: products.filter((product) => !product?.isApproved),
            totalProducts: products,
            fetchingAllProducts: false,
        };
    }
    if (action.type === 'LOAD_PROMOTION_PRICE') {
        return {
            ...state,
            promotionPrice: Number(
                Web3.utils.fromWei(
                    Number(action.promotionPrice).toLocaleString('fullwide', { useGrouping: false }),
                    'ether'
                )
            ),
        };
    }
    if (action.type === 'LOAD_USER_PRODUCTS') {
        return {
            ...state,
            userProducts: action.userProducts,
        };
    }
    if (action.type === 'GET_DIGITAL_FILES') {
        return {
            ...state,
            digitalFiles: action.digitalFiles?.map((file) => {
                return {
                    productId: Number(file[0]),
                    productOwner: file[5],
                    productShopId: Number(file[2][0]),
                    productShopName: file[2][1],
                    productShopSlug: createSlug(file[2][1]),
                    productShopLogo: file[2][2],
                    productName: file[1][0],
                    productSlug: createSlug(file[1][0]),
                    productDescription: file[1][1],
                    productCategory: file[1][2],
                    productType: file[1][3],
                    productSrc: file[1][4],
                    productGallery: file[1][5],
                    productCompatibility: file[1][6],
                    productThumbnail: file[1][6],
                    isApproved: true,
                    productPrice: Number(
                        Web3.utils.fromWei(
                            Number(file[3]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                            'ether'
                        )
                    ),
                    productTokenPrice:
                        Number(
                            Web3.utils.fromWei(
                                Number(file[3]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                                'ether'
                            )
                        ) * usdToken,
                    isBlocked: file[6] === 2,
                    promoted: Number(file[9]) * 1000 > new Date().getTime(),
                    prmotionEndTime: formatDate(Number(file[9]) * 1000),
                    productRating: Number(file[8]) / 10,
                    isDigital: true,
                };
            }),
        };
    }

    return defaultShopState;
};

// eslint-disable-next-line no-unused-vars
let usdPrice = 0;
let usdToken = 0;

const ShopProvider = (props) => {
    const [shopState, dispatchShopAction] = useReducer(shopReducer, defaultShopState);

    const loadContractHandler = (shopContract) => {
        dispatchShopAction({ type: 'CONTRACT', shopContract: shopContract });
        return shopContract;
    };

    const loadMembershipContractHandler = (membershipContract) => {
        dispatchShopAction({ type: 'MEMBERSHIP_CONTRACT', membershipContract: membershipContract });
        return membershipContract;
    };

    const getShopContractAbiHandler = (abi) => {
        dispatchShopAction({ type: 'GET_ABI', shopContractAbi: abi });
    };

    const getMemberShopContractAbiHandler = (abi) => {
        dispatchShopAction({ type: 'GET_MEMBERSHIP_ABI', membershipAbi: abi });
    };

    const loadPaymentTokenAddressHandler = async (contract) => {
        const paymentToken = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: '_token',
        });
        dispatchShopAction({ type: 'GETPAYMENTTOKEN', paymentToken: paymentToken });
        return paymentToken;
    };

    const loadPaymentTokenContractHandler = (token, abi) => {
        const paymentTokenContract = getContract({
            address: token,
            abi: abi,
        });

        dispatchShopAction({ type: 'GETPAYMENTTOKENCONTRACT', paymentTokenContract: paymentTokenContract });
        return paymentTokenContract;
    };

    const loadPaymentTokenBalanceHandler = async (token, abi, address) => {
        const tokenBalance = await readContract({
            address: token,
            abi: abi,
            functionName: 'balanceOf',
            overrides: address,
        });

        dispatchShopAction({ type: 'GET_BALANCE', tokenBalance: tokenBalance });
        return tokenBalance;
    };

    const loadAllShopsHandler = async (contract) => {
        const allShops = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getAllShops',
        });

        console.log(allShops);

        const allProducts = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getAllProducts',
        });

        dispatchShopAction({ type: 'LOAD_ALL_SHOPS', payload: { allShops, allProducts } });

        return { allShops, allProducts };
    };

    const loadAllProductsHandler = async (contract) => {
        const allProducts = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getAllProducts',
        });

        dispatchShopAction({ type: 'LOAD_ALL_PRODUCTS', allProducts: allProducts });

        return allProducts;
    };

    const loadUserProductsHanlder = async (contract) => {
        const userProducts = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getUserProducts',
        });

        dispatchShopAction({ type: 'LOAD_USER_PRODUCTS', userProducts: userProducts });
        return userProducts;
    };

    const loadUserShopsHandler = async (contract, address) => {
        const userShops = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getShopsForUser',
            overrides: address,
        });
        dispatchShopAction({ type: 'LOAD_USER_SHOPS', userShops: userShops });
        return userShops;
    };

    const loadShopCreationCostHandler = async (contract, abi) => {
        const shopCreationCost = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'openShopCost',
        });
        dispatchShopAction({ type: 'LOAD_SHOP_CREATION_COST', shopCreationCost: shopCreationCost });
        return shopCreationCost;
    };

    const loadShopVerifyCostHandler = async (contract, abi) => {
        const shopVerifyCost = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'goldShopCost',
        });
        dispatchShopAction({ type: 'LOAD_SHOP_VERIFY_COST', shopVerifyCost: shopVerifyCost });
        return shopVerifyCost;
    };

    const loadGoldCheckCostHandler = async (contract, abi) => {
        const goldCheckCost = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'goldCheckCost',
        });
        dispatchShopAction({ type: 'LOAD_GOLD_CHECK_COST', goldCheckCost: goldCheckCost });
        return goldCheckCost;
    };

    const loadProductReviewsHandler = async (contract, productId, abi, funcName) => {
        const reviews = await readContract({
            address: contract.address,
            abi: abi || shopState.shopContractAbi,
            functionName: funcName || 'getProductRates',
            args: [productId],
        });

        dispatchShopAction({ type: 'LOAD_PRODUCT_REVIEWS', reviews: reviews });
        return reviews;
    };

    const loadStTokenHandler = async (contract, abi, account) => {
        const stTokenAddress = await readContract({
            address: contract.address,
            abi: abi,
            functionName: '_token',
        });

        const stToken = stTokenAddress && {
            address: stTokenAddress,
            contract: getContract({
                address: stTokenAddress,
                abi: Integration,
            }),
            symbol: await readContract({
                address: stTokenAddress,
                abi: Integration,
                functionName: 'symbol',
            }),
            balance: Web3.utils.fromWei(
                Number(
                    await readContract({
                        address: stTokenAddress,
                        abi: Integration,
                        functionName: 'balanceOf',
                        args: [account],
                    })
                )
                    .toLocaleString('fullwide', { useGrouping: false })
                    .toString(),
                'ether'
            ),
        };

        dispatchShopAction({ type: 'GET_ST_TOKEN', stToken: stToken });
        return stToken;
    };

    const loadPaymentTokenPriceInUSDHandler = async (tokenId) => {
        try {
            const res =
                await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId?.toLowerCase()}&vs_currencies=usd
                `);
            const data = await res.json();
            const tokenInUSD = data[`${tokenId?.toLowerCase()}`]?.usd;
            usdPrice = tokenInUSD;
            usdToken = parseFloat((1 / tokenInUSD).toFixed(2));

            dispatchShopAction({
                type: 'GET_TOKEN_IN_USD',
                payload: { tokenInUSD: parseFloat(tokenInUSD), usdToken: usdToken },
            });
            return { tokenInUSD, usdToken };
        } catch (error) {
            console.log(error);
        }
    };

    const loadPromotionPriceHandler = async (contract, abi) => {
        const promotionPrice = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'promotionPrice',
        });

        dispatchShopAction({ type: 'LOAD_PROMOTION_PRICE', promotionPrice: promotionPrice });

        return promotionPrice;
    };

    const loadDigitalFilesHandler = async (contract, abi) => {
        const digitalFiles = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'getAllFiles',
        });
        dispatchShopAction({ type: 'GET_DIGITAL_FILES', digitalFiles: digitalFiles });

        console.log('DIGitAL FILES', digitalFiles);
        return digitalFiles;
    };

    const shopContext = {
        shopContract: shopState.shopContract,
        membershipContract: shopState.membershipContract,
        membershipAbi: shopState.membershipAbi,
        shopContractAbi: shopState.shopContractAbi,
        paymentTokenContract: shopState.paymentTokenContract,
        paymentToken: shopState.paymentToken,
        stToken: shopState.stToken,
        shopCreationCost: shopState.shopCreationCost,
        shopVerifyCost: shopState.shopVerifyCost,
        goldCheckCost: shopState.goldCheckCost,
        tokenBalance: shopState.tokenBalance,
        allShops: shopState.allShops,
        allProducts: shopState.allProducts,
        pendingProducts: shopState.pendingProducts,
        productReviews: shopState.productReviews,
        totalProducts: shopState.totalProducts,
        userShops: shopState.userShops,
        userProducts: shopState.userProducts,
        fetchingAllShops: shopState.fetchingAllShops,
        fetchingAllProducts: shopState.fetchingAllProducts,
        tokenInUSD: shopState.tokenInUSD,
        usdToken: shopState.usdToken,
        promotionPrice: shopState.promotionPrice,
        digitalFiles: shopState.digitalFiles,
        loadShopContract: loadContractHandler,
        getShopContractAbi: getShopContractAbiHandler,
        loadAllShops: loadAllShopsHandler,
        loadUserShops: loadUserShopsHandler,
        loadAllProducts: loadAllProductsHandler,
        loadUserProducts: loadUserProductsHanlder,
        loadProductReviews: loadProductReviewsHandler,
        loadPaymentTokenAddress: loadPaymentTokenAddressHandler,
        loadPaymentTokenContract: loadPaymentTokenContractHandler,
        loadPaymentTokenBalance: loadPaymentTokenBalanceHandler,
        loadPaymentTokenPriceInUSD: loadPaymentTokenPriceInUSDHandler,
        loadPromotionPrice: loadPromotionPriceHandler,
        loadStToken: loadStTokenHandler,
        loadMembershipContract: loadMembershipContractHandler,
        getMemberShopContractAbi: getMemberShopContractAbiHandler,
        loadShopCreationCost: loadShopCreationCostHandler,
        loadGoldCheckCost: loadGoldCheckCostHandler,
        loadShopVerifyCost: loadShopVerifyCostHandler,
        loadDigitalFiles: loadDigitalFilesHandler,
    };

    return <ShopContext.Provider value={shopContext}>{props.children}</ShopContext.Provider>;
};

export default ShopProvider;
