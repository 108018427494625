/* -----------------------------------------------------------------------------------------
    THSI FILE CONTAINS SOME STATIC DATA TO FILL SOME DIFFERENT COMPONENTS
----------------------------------------------------------------------------------------- */

import React from 'react';

/* FAQS LIST - ADD OR DELETE FROM THEM AS YOU NEED */
export const FAQS = [
    {
        question: 'What is the DIFI Market?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    DIFI Market is an Online Shopping Mall, Decentralized and runs on the CORE Blockchain, it allows
                    anyone to Buy and Sell any type of product, open their own stores, check them out, leave feedback
                    after the orders, promote the products, and pay only the 1% commission on sales. The USDT Token is
                    used to pay for the products, and DIFI Token for the Services Fees based on the price in Dollars that is entered by the sellers and with
                    the exchange to the market price at the time of the order, it is possible to manage the product
                    stock, the shipping price for each continent and a system Advanced Dispute. These are some features
                    of the first decentralized global marketplace!{' '}
                </p>
            </div>
        ),
    },
    {
        question: 'What is DIFI Token?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    The DIFI is the Utility Token that is used to pay for services in the Market. The reference
                    price is in Dollars, at the time of purchase the buyer will pay the sum in USDT with the market
                    exchange rate at that time. On the DIFI markets, you can only pay with USDT for products and DIFI for services that are easily
                    tradable on various Decentralized and Centralized exchanges around the world.
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>DIFI CORE Contract</strong> –
                    <a href='https://scan.coredao.org/token/0x2c291980eeabd3571cee64e76e8c481e773919ac'>
                        0x2c291980eeabd3571cee64e76e8c481e773919ac
                    </a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>USDT CORE Contract</strong> –
                    <a href='https://scan.coredao.org/token/0x900101d06a7426441ae63e9ab3b9b0f63be145f1'>
                        0x900101d06a7426441ae63e9ab3b9b0f63be145f1
                    </a>
                </p>
				<p className='mb-2'>
                    <strong className='text-white'>Max/Circulating Supply</strong> – 10,000,000 DIFI
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Tax</strong> – Buy/Transfer: 0%, Sell: 5%
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Whitepaper</strong> –{' '}
                    <a href='https://difi.market/WhitePaper_difi.pdf'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Buy DIFI @ CORE</strong> –{' '}
                    <a href='https://icecreamswap.com/swap?chainId=1116&outputCurrency=0x2C291980Eeabd3571cEE64e76e8c481E773919Ac'>
                        DIFI/CORE
                    </a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Buy DIFI @ BSC</strong> –{' '}
                    <a href='https://pancakeswap.finance/swap?outputCurrency=0x0938a5d325A8496c186Cf122946e9dD22f8a625b'>
                        DIFI/WBNB
                    </a>
                </p>
				<p className='mb-2'>
                    <strong className='text-white'>Official Twitter</strong> –{' '}
                    <a href='https://twitter.com/difimarket'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Official Telegram</strong> –{' '}
                    <a href='https://t.me/difitoken'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Official Instagram</strong> –{' '}
                    <a href='https://instagram.com/difimarket'>Click Here</a>
                </p>
            </div>
        ),
    },
    {
        question: 'How do you start?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>Starting:</p>
                <ul className='mb-0 text-white'>
                    <li>Connect your Crypto Wallet</li>
                    <li>Register as a seller or as a buyer</li>
                    <li>Verify your email by clicking on the link you receive.</li>
                </ul>
                <p className='mb-2'>Sellers:</p>
                <ul className='mb-0 text-white'>
                    <li>You can open one or more shops.</li>
                    <li>By following the procedure, you can verify your shop by receiving the blue check.</li>
                    <li>Put your products on sale.</li>
                </ul>
                <p className='mb-2'>Buyers:</p>
                <ul className='mb-0 text-white'>
                    <li>Purchase the products</li>
                    <li>Leave Feedback after completed orders.</li>
                    <li>Request Refunds.</li>
                </ul>
                <p className='mb-2'>Dispute System:</p>
                <ul className='mb-0 text-white'>
                    <li>Order problem management.</li>
                    <li>Verify information with support staff.</li>
                    <li>Payment release only after completed orders or disputes resolution.</li>
                </ul>
            </div>
        ),
    },
    {
        question: 'What are the commissions?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    Sales commissions are 1% on sales, deducted upon order completion and only on products sold. There
                    are no other hidden fees.
                </p>
            </div>
        ),
    },
    {
        question: 'How are payments handled?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    Buyers pay at the time of order in USDT, the payment remains in the system until the order is
                    completed.
                </p>
                <p className='mb-2'>
                    Method 1: The Seller sends the product confirming the shipment and entering the tracking code (url),
                    it is recommended to photograph the product and the package before shipping. The buyer receives the
                    product and confirms receipt. After confirmation the payment is released to the seller minus 1%
                    commission. At this point the order is completed. Method 2: Opening dispute, in case the seller does
                    not ship and does not refund, or in case the buyer does not confirm receipt of the product, a
                    dispute can be opened which is managed manually by the Staff, and where the verifications based on
                    the evidence release the payment to the seller, or refund the buyer. In this case the 1%
                    commission does not apply.
                </p>
                <p className='mb-2'>
                    Payments: Payments are made in USDT, based on the price of the product in Dollars, the exchange is
                    made at the moment, in any case, if a refund or normal payment is made, if the USDT paid is x, the
                    USDT paid is returned and no longer the price in dollars.
                </p>
            </div>
        ),
    },
    {
        question: 'How does the Shop work?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    The DIFI Market works totally on the CORE Blockchain, for each modification, insertion, action
                    it is necessary to verify it with a transaction on the Blockchain. There are no external databases,
                    or alternative ways, the Blockchain guarantees the security of the entire system, and this leads it
                    to high levels of security. The USDT in the profiles are associated with the wallet used in
                    registration, no one else can request, move or withdraw them except the wallet connected to the
                    Store. Even the entire administrative and Dispute management part works on the Blockchain, there is
                    absolutely no offline, and no database or file that could be at risk.
                </p>
            </div>
        ),
    },
    {
        question: 'Do you provide support for your products?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    All the products on sale come from external sellers and DIFI Market is not responsible for the
                    contents, the Sellers will have the obligation to clarify all the options, whether they are of
                    shipments, arrival times and specifications of each product they sell. Staff manages disputes, new
                    product approvals and store audits.
                </p>
            </div>
        ),
    },
    {
        question: 'Privacy?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    All data is encrypted on the Blockchain, only during the shipping procedure do the seller and the
                    buyer see the data to arrange for the shipment and manage any disputes about an order. All data is
                    confidential at Blockchain and Web3 level.
                </p>
            </div>
        ),
    },
    {
        question: 'Can I Sell Cryptocurrencies?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    In theory yes, in the tracking url address, instead of shipping, you can enter the url for the transfer of the
                    cryptocurrency for sale, obviously indicating this information in the description of the product for
                    sale.
                </p>
            </div>
        ),
    },
    {
        question: 'More questions?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    If you can’t find the answers here,{' '}
                    <a href='/contact' className='text-primary'>
                        get in touch
                    </a>
                    . We will be happy to help.
                </p>
            </div>
        ),
    },
];

export const countries = [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'AndorrA', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: "Cote D'Ivoire", value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: "Korea, Democratic People'S Republic of", value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: "Lao People'S Democratic Republic", value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Surilabel', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' },
];

export const languages = [
    { code: 'en', key: 'English' },
 //   { code: 'fr', key: 'French' },
 //   { code: 'it', key: 'Italian' },
//    { code: 'zh', key: '中文' },
//    { code: 'ru', key: 'Русский' },
  //  { code: 'es', key: 'Spanish' },
 //   { code: 'hi', key: 'Hindi' },
 //   { code: 'po', key: 'Polish' },
];
